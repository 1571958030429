import React, { Component } from 'react';

const bodyText1 = <div>
  <p>
    Etsimme järjestyksenvalvojia Helsinkiin.
  </p>

  <p>
    Haemme joukkoomme reipashenkisiä ja motivoituneita järjestyksenvalvojia ravintola- ja
    tapahtumakohteisiin. Työvuorot painottuvat illoille ja öille, mutta tarjolla voi olla myös satunnaisia
    päivävuoroja. Hakijalta toivomme sitoutumista tarjottavaan työhön.
  </p>

  <p>
    Työskentely kohteet ovat Helsingissä tai pääkaupunkiseudulla. Voimassa oleva järjestyksenvalvojakortti on
    pakollinen.
  </p>

  <p>
    Katsomme eduksi hakijan vartijan koulutuksen.
  </p>

  <p>
    Palkkauksessa sovelletaan vartiointialan työehtosopimusta.
  </p>

  <p>
    NSA on kasvava ja kehittyvä yksityisen turvallisuusalan palveluita tarjoava yhtiö, jossa henkilöstöstä
    välitetään. Tarjoamme hyvät työkaverit ja hienon työyhteisön, jossa ammattitaitoa ja henkilökohtaista
    kehitystä arvostetaan.
  </p>
</div>;

const bodyText2 = <div>
  <p>
    Etsimme järjestyksenvalvojia Tampereelle.
  </p>

  <p>
    Haemme joukkoomme reipashenkisiä ja motivoituneita järjestyksenvalvojia ravintola- ja
    tapahtumakohteisiin. Työvuorot painottuvat illoille ja öille. Hakijalta toivomme sitoutumista tarjottavaan
    työhön.
  </p>
  
  <p>
    Työskentely kohteet ovat Tampereella. Voimassa oleva järjestyksenvalvojakortti on pakollinen.
    Katsomme eduksi hakijan vartijan koulutuksen, sekä mahdollisuuden oman auton käyttöön ja
    työskentelyyn myös Tamperetta ympäröivillä alueilla.
  </p>

  <p>
    Palkkauksessa sovelletaan vartiointialan työehtosopimusta.
  </p>

  <p>
    NSA on kasvava ja kehittyvä yksityisen turvallisuusalan palveluita tarjoava yhtiö, jossa henkilöstöstä
    välitetään. Tarjoamme hyvät työkaverit ja hienon työyhteisön, jossa ammattitaitoa ja henkilökohtaista
    kehitystä arvostetaan.
  </p>
</div>;

const JobList = [
  {
    id: "6ad35e3d-65c6-4935-8494-568678925208",
    title: "Järjestyksenvalvojia Helsinkiin",
    leadIn: "Etsimme järjestyksenvalvojia Helsinkiin",
    quantity: "Jatkuva haku",
    city: "Helsinki",
    body: bodyText1,
    emails: ["ossi.pietilainen@nordicsec.fi", "mikko.seppanen@nordicsec.fi"],
    type: 0
  },
  {
    id: "2acff93c-131b-4063-9c8d-32827921e38e",
    title: "Järjestyksenvalvojia Tampereelle",
    leadIn: "Etsimme järjestyksenvalvojia Tampereelle",
    quantity: "Jatkuva haku",
    city: "Tampere",
    body: bodyText2,
    email: ["jan.johansson@nordicsec.fi", "tero.toivanen@nordicsec.fi"],
    type: 0
  }
];

export default JobList;